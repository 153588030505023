import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import { inviteUser } from "../services/inviteUserService";

const InviteUser = (props) => {

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: "",
      permissions: "auth",
    },
  });

  const handleClose = () => {
    props.handleClose(!props.dialogOpen);
  };

  const onSubmit = async (data) => {
    inviteUser({ email: data.email });
    props.handleClose(!props.dialogOpen);
  }

  return (
    <Dialog fullWidth open={props.dialogOpen ? true : false} onClose={handleClose}>
      <DialogTitle>Invite New Team Member</DialogTitle>
      <DialogContent>
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField sx={{ m: 1 }} {...field} label="User Email" fullWidth />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button variant="outlined" color="success" onClick={handleSubmit(onSubmit)}>
          Invite User
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUser;
