import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: "auth",
    initialState: {
        value: {
            accessToken: null,
            user: {},
            admin: false,
        }
    },
    reducers: {
        login: (state, action) => {
            state.value.accessToken = action.payload.accessToken;
            state.value.admin = action.payload.admin;
        },
        logout: (state) => {
            state.value.accessToken = null;
        },
        setUserData: (state, action) => {
            state.value.user = action.payload;
        }
    }
})

export const { login, logout, setUserData } = authSlice.actions;

export default authSlice.reducer