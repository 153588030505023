import { Grid, TextField, Button, Alert, Typography } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { sendMagicLinkService } from "../services/loginService";
import { useState } from "react";

const Login = () => {
  const [hasSentMagicLink, setHasSentMagicLink] = useState(false);
  const { control, handleSubmit, formState: { errors }, setError } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    sendMagicLinkService(data).then((res) => {
      if (res.ok) {
        setHasSentMagicLink(true);
        // dispatch(login({ accessToken: res.accessToken, admin: res.admin}))
        // navigate("/app/dashboard", { replace: true });
      } else {
        setError("email")
        setError("password")
        
        console.log("Invalid Login")
      }
    }).catch((e) => console.log(e));  
  };
  
  return (
    <>
    { !hasSentMagicLink ? (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container textAlign="center" spacing={3} justifyContent="center">
        <Grid item xs={7}>
          <Controller
            name="email"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <TextField fullWidth {...field} label="Email" />}
          />
          { errors.email ? <Alert severity="error">Invalid Email</Alert> : <></> }
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained">
            Next
          </Button>
        </Grid>
      </Grid>
    </form> )
    : <Typography>Login link sent, please check your email to sign in</Typography> }
  </>
  );
};

export default Login;
