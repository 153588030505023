import { Button, Grid } from "@mui/material";
import LookupTool from "./lookupTool";
import PropertyTable from "./propertyTable";
import { useState } from "react";
import { Add } from "@mui/icons-material";
import Legend from "./legend";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { resetContacts } from "../features/contactSlice";

const QuickAssessor = () => {
  const [toolOpen, toggleTool] = useState(true);
  const cidSelected = useSelector((state) => state.assessor.value.form.cid);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(resetContacts())
  }, [])
  return (
    <Grid container padding={3} spacing={3}>
      {cidSelected ? (
        <Grid item xs={12}>
          <Legend />
        </Grid>
      ) : (
        <></>
      )}

      {toolOpen ? (
        <Grid item xs={3}>
          <LookupTool toggleLookup={toggleTool} />
        </Grid>
      ) : (
        <Grid item xs={12} textAlign="left">
          <Button
            color="inherit"
            variant="outlined"
            onClick={() => toggleTool(true)}
          >
            <Add />
          </Button>
        </Grid>
      )}

      <Grid item xs={toolOpen ? 9 : 12}>
        <PropertyTable toggleLookup={toggleTool} />
      </Grid>
    </Grid>
  );
};

export default QuickAssessor;
