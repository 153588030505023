import logo from "./logo.svg";
import { Routes, Route, useNavigate } from "react-router-dom";
import Landing from "./components/landing";
import Auth from "./components/auth";
import Footer from "./components/footer";
import NavDrawer from "./components/navDrawer";
import QuickAssessor from "./components/quickAssessor";
import Dashboard from "./components/dashboard";
import Contacts from "./components/contacts";
import Admin from "./components/admin";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Snackbar,
} from "@mui/material";
import Loading from "./components/loading";
import { refreshTokenService } from "./services/refreshService";
import { Box } from "@mui/system";
import { login, logout } from "./features/authSlice";
import NewUser from "./components/newUser";
import Properties from "./components/properties";
import { HandleLogin } from "./components/handleLogin";
import ImportModal from "./components/importModal";

function App() {
  const loggedIn = useSelector((state) => state.auth.value.accessToken);
  const snackbar = useSelector((state) => state.snackbar.value);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function refresh() {
      const res = await refreshTokenService();
      if (res.ok) {
        dispatch(login({ accessToken: res.accessToken, admin: res.admin }));
      } else {
        navigate("/auth", { replace: true });
        dispatch(logout());
      }
    }
    refresh();
  }, []);

  let protectedRoutes = [
    <Route key={0} path="/app" element={<NavDrawer />} />,
    <Route
      key={1}
      path="/app/contacts"
      element={
        <>
          <NavDrawer />
          <Contacts />
        </>
      }
    />,
    <Route
      key={2}
      path="/app/dashboard"
      element={
        <>
          <NavDrawer />
          <Dashboard />
        </>
      }
    />,
    <Route
      key={3}
      path="/app/quickAssessor"
      element={
        <>
          <NavDrawer />
          <ImportModal />
          <QuickAssessor />
        </>
      }
    />,

    <Route
      key={4}
      path="/app/newUser"
      element={
        <>
          <NavDrawer />
          <NewUser />
        </>
      }
    />,
    <Route
      key={5}
      path="/app/properties"
      element={
        <>
          <NavDrawer />
          <Properties />
        </>
      }
    />,
    <Route
      key={3}
      path="/app/admin"
      element={
        <>
          <NavDrawer />
          <Admin />
        </>
      }
    />,
  ];

  return (
    <Box
      sx={{
        backgroundImage: `linear-gradient(to top left, rgba(0, 38, 255, .2), white 45%)`,
        minHeight: "100vh",
      }}
    >
      <Snackbar
        open={snackbar.open}
        autoHideDuration={1000}
        message={snackbar.message}
      />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login/:token" element={<HandleLogin />} />
        <Route path="/auth" element={<Auth />} />
        {loggedIn ? (
          protectedRoutes.map((r) => {
            return r;
          })
        ) : (
          <Route path="/app/*" element={<Loading />} />
        )}
        <Route
          path="/*"
          element={
            <Typography variant="h5" textAlign="center" sx={{ mt: 1 }}>
              404 Error Not Found
            </Typography>
          }
        />
        <Route
          path="/error"
          element={
            <Typography variant="h5" textAlign="center" sx={{ mt: 1 }}>
              An Error Occured
            </Typography>
          }
        />
      </Routes>
      <Footer />
    </Box>
  );
}

export default App;
