import { api, getAuthHeader } from "../axiosConfig";
import { store } from "../store";
import { notifyService } from "./notifyService";
export async function getContacts(req) {
  return await api
    .post(
      "/contacts/searchContact",
      {
        name: req,
      },
      {
        headers: {
          Authorization: `Bearer ${store.getState().auth.value.accessToken}`,
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
}

export async function getContact(id) {
  return await api
    .get("/contacts/getContact", { params: { id: id }, headers: getAuthHeader() })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return console.log(err);
    });
}

export async function addContacts(data) {
  return await api
    .post("/contacts/addContact", data, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.value.accessToken}`,
      },
    })
    .then((res) => {
      notifyService("Contact Added!");
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
}

export async function updateContact(data) {
  return await api.put("/contacts/updateContact", data, { headers: getAuthHeader()}).then((res) => {
    notifyService(res.data);
    return res.data;
  }).catch((err) => {
    notifyService(err.message);
    throw err;
  })
}

export async function deleteContact(id) {
  return await api
    .delete("/contacts/deleteContact", {
      data: { id: id },
      headers: getAuthHeader(),
    })
    .then((res) => {
      notifyService(res.data);
      return res.data;
    })
    .catch((err) => console.log(err));
}
