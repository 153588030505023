import { api, getAuthHeader } from "../axiosConfig";
import { notifyService } from "./notifyService";


export async function updateUserPermissions(data) {
    api.put("/admin/updateUserPermissions", data, { headers: getAuthHeader()}).then((res) => {
        notifyService(res.data)
        return true
    }).catch((err) => {
        notifyService(err.message);
        throw err
    })

}
