import {
  Grid,
  TextField,
  Typography,
  Card,
  CircularProgress,
  CardHeader,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Checkbox,
  CardContent,
  CardActions,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  getContacts,
  getContact,
  updateContact,
} from "../services/contactService";
import CreateContact from "./createContact";
import { deleteContact } from "../services/contactService";
import { useDispatch, useSelector } from "react-redux";
import {
  resetContacts,
  setResults,
  setSearchTerm,
  setSelectedContact,
} from "../features/contactSlice";
import { setContact } from "../features/assessorSlice";
import { useForm, Controller } from "react-hook-form";

const Contacts = () => {
  const contacts = useSelector((state) => state.contact.value);

  const [dialogOpen, toggleDialog] = useState(false);

  const dispatch = useDispatch();

  const handleSearch = async (term) => {
    dispatch(setSearchTerm(term));
    const res = await getContacts(term);
    dispatch(setResults(res));
  };

  const handleCheck = (data) => {
    if (data.id !== contacts.selectedContact) {
      dispatch(setSelectedContact(data.id));
      dispatch(setContact(data.id));
      reset(data);
    } else {
      dispatch(setContact(null));
      dispatch(setSelectedContact(null));
    }
  };

  const onUpdate = async (data) => {
    await updateContact(data).then(async () => {
      const res = await getContacts(data.name);
      dispatch(setSearchTerm(data.name));
      dispatch(setResults(res));
      reset(data);
    });
  };

  const handleDelete = async (id) => {
    await deleteContact(id).then(async () => {
      const res = await getContacts(contacts.searchTerm);
      dispatch(setResults(res));
    });
  };

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm({
    defaultValues:
      contacts.tempData?.name !== undefined ? contacts.tempData : {},
  });

  return (
    <Grid container spacing={3} justifyContent="center">
      <CreateContact dialogOpen={dialogOpen} handleClose={toggleDialog} />
      <Grid item xs={12} textAlign="center">
        <Button
          onClick={() => toggleDialog(!dialogOpen)}
          variant="outlined"
          color="success"
        >
          Create Contact
        </Button>
      </Grid>
      <Grid item xs={6} mx="auto" mb={3}>
        <TextField
          label="Search Contacts"
          value={contacts.searchTerm}
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
          fullWidth
        />
      </Grid>

      <Grid item xs={10} mx="auto" textAlign="center">
        {contacts.results ? (
          contacts.results.map((r) => {
            return (
              <Card key={r.id} sx={{ mb: 3, overflowX: "scroll" }}>
                <Checkbox
                  checked={r.id === contacts.selectedContact}
                  onChange={() => handleCheck(r)}
                />
                <CardHeader
                  title={r.company + " / " + r.name + " / " + r.phone_number}
                />
                {r.id === contacts.selectedContact ? (
                  <>
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Controller
                            name="name"
                            control={control}
                            render={({ field }) => (
                              <TextField {...field} fullWidth label="Name" />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="phone_number"
                            control={control}
                            render={({ field }) => (
                              <TextField fullWidth label="Phone #" {...field} />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                              <TextField fullWidth label="Email" {...field} />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="company"
                            control={control}
                            render={({ field }) => (
                              <TextField fullWidth label="Company" {...field} />
                            )}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Controller
                            name="secondary_contact"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                multiline
                                minRows={2}
                                label="Secondary Contact"
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="secondary_phone"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                multiline
                                minRows={2}
                                label="Secondary Phone #"
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="secondary_email"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                label="Secondary Email"
                                multiline
                                minRows={2}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name="notes"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                fullWidth
                                label="Notes"
                                multiline
                                minRows={6}
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="contained"
                        color="warning"
                        disabled={!isDirty}
                        onClick={handleSubmit(onUpdate)}
                      >
                        Update Contact
                      </Button>
                      <Button
                        color="error"
                        variant="outlined"
                        onClick={() => {
                          handleDelete(contacts.selectedContact);
                        }}
                      >
                        Delete Contact
                      </Button>
                    </CardActions>
                  </>
                ) : (
                  <></>
                )}
              </Card>
            );
          })
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  );
};

export default Contacts;
