import { configureStore } from "@reduxjs/toolkit"
import assessorReducer from "./features/assessorSlice"
import authReducer from "./features/authSlice"
import snackbarReducer from "./features/snackbarSlice"
import contactReducer from "./features/contactSlice"
import cidReducer from "./features/cidSlice"

export const store = configureStore({
    reducer: {
        assessor: assessorReducer,
        auth: authReducer,
        snackbar: snackbarReducer,
        contact: contactReducer,
        cid: cidReducer
    }
})