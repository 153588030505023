import { Grid, Typography, } from "@mui/material";
import logo from "../assets/logo.png";
import { theme } from "../theme"

import Login from "./login";

const Auth = () => {
  return (
    <Grid container textAlign="center" spacing={3}>
      <Grid item xs={12} mt="5%">
        <img src={logo} alt="Leithead Consulting Logo" />
      </Grid>
      <Grid item xs={12}>
          <Grid container justifyContent="center">
              <Grid item sx={{ borderBottom: `1px solid ${theme.palette.primary.main}`}}>
                  <Typography>Login</Typography>
              </Grid>
          </Grid>
      </Grid>
      <Grid item xs={12}>
        <Login />
      </Grid>
    </Grid>
  );
};

export default Auth;
