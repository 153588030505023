import logo from "../assets/logo.png";
import { Grid, Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
const Landing = () => {

  const navigate = useNavigate()
  const loggedIn = useSelector((state) => state.auth.value.accessToken);

  return (
    <Grid container textAlign="center" mt="5%" spacing={3}>
        <Grid item xs={12} mb="1%">
          <img src={logo} alt="Leithead Consulting Logo" />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant="contained" onClick={() => loggedIn ? navigate("/app/dashboard") : navigate("/auth")}>
            Start
          </Button>
        </Grid>
    </Grid>
  ) 
};

export default Landing;
