import axios from "axios"
import { store } from "./store"


const url = process.env.NODE_ENV !== 'development' ? "https://test.portalmerch.com/api" : "http://localhost:3001/api";
const origin = process.env.NODE_ENV !== 'development' ? "https://test.portalmerch.com" : "http://localhost:3001";

export const api = axios.create({
    baseURL: url,
    timeout: 8000,
    headers: { "Access-Control-Allow-Origin": origin }
});


export function getAuthHeader() {
    return { "Authorization" : `Bearer ${store.getState().auth.value.accessToken }`, "Access-Control-Allow-Origin" : origin}
}