import {
  Checkbox,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  Typography,
  Chip,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { getContact, getContacts } from "../services/contactService";
import {
  searchProperties,
  updatePropertyContact,
} from "../services/propertyService";
import Contacts from "./contacts";
import {
  resetContacts,
  setResults,
  setSearchTerm,
  setSelectedContact,
  setTempData,
} from "../features/contactSlice";
import { useDispatch, useSelector } from "react-redux";
import { setContact } from "../features/assessorSlice";

const Properties = () => {
  const [filter, setFilter] = useState(1);
  const [data, setData] = useState("");
  const [selected, setSelected] = useState(null);
  const [properties, setProperties] = useState();
  const contactInfo = useSelector((state) => state.contact.value);
  const [touched, setTouched] = useState(-1);
  const dispatch = useDispatch();

  const onSubmit = (data) => console.log(data);
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      textAlign="center"
      pt={3}
    >
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel>Search By</InputLabel>
          <Select
            label="Search By"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <MenuItem value={1}>Parcel ID</MenuItem>
            <MenuItem value={2}>Address</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        <TextField
          onChange={async (e) => {
            searchProperties(
              e.target.value.trim(),
              filter === 1 ? "parcelID" : "address"
            ).then((r) => {
              setProperties(r);
            });
            setData(e.target.value);
            dispatch(resetContacts())
            setSelected(null)
          }}
          value={data}
          label={filter === 1 ? "Enter Parcel ID" : "Enter Address"}
          fullWidth
        />
      </Grid>
      {properties ? (
        properties.slice(0, 20).map((p, i) => {
          return (
            <Grid key={i} item xs={9}>
              <Card>
                <CardHeader
                  title={
                    <>
                      <Checkbox
                        checked={selected === p.id}
                        onChange={async () => {
                          dispatch(resetContacts());
                          if (selected === p.id) {
                            setSelected(null);
                          } else {
                            const contactData = await getContact(p.contact_id);
                            if (contactData) {
                              dispatch(setSearchTerm(contactData.name));
                              dispatch(setResults([contactData]));
                              dispatch(setSelectedContact(contactData.id));
                              dispatch(setTempData(contactData));
                            } else {
                              dispatch(resetContacts());
                            }
                            setTouched(p.contact_id);
                            setSelected(p.id);
                          }
                        }}
                      />
                      {p.parcelID}
                    </>
                  }
                />
                <CardContent>
                  <Table sx={{ mb: 3 }}>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">Owner Name</TableCell>
                        <TableCell align="center">{p.owner_name}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">Owner Mailing</TableCell>
                        <TableCell align="center">{p.owner_mailing}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">Building Address</TableCell>
                        <TableCell align="center">
                          {p.building_address}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">Assessed Value</TableCell>
                        <TableCell align="center">
                          ${p.assessor_value}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="left">CID Value</TableCell>
                        <TableCell align="center">${p.cid_value}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  {selected !== p.id ? (
                    <></>
                  ) : (
                    <>
                      <Contacts />
                      <Button
                        variant="contained"
                        color="warning"
                        disabled={contactInfo.selectedContact === touched}
                        onClick={() => {
                          updatePropertyContact(
                            selected,
                            contactInfo.selectedContact
                          );
                          setTouched(contactInfo.selectedContact);
                          
                        }}
                      >
                        Update Property Data
                      </Button>
                    </>
                  )}
                </CardContent>
              </Card>
              <br />
            </Grid>
          );
        })
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default Properties;
