import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { reportProblem } from "../services/reportProblem";
const Footer = () => {
  const [dialogOpen, toggleDialog] = useState(false);
  const [message, setMessage] = useState("");

  const handleClose = () => {
    toggleDialog(!dialogOpen);
  };
  const onSubmit = () => {
    reportProblem(message);
    toggleDialog(!dialogOpen)
  }
  return (
    <Grid container textAlign="center" sx={{ opacity: ".5" }} p={3}>
      <Grid item xs={9}>
        <Dialog fullWidth open={dialogOpen} onClose={() => handleClose()}>
          <DialogTitle>Report a problem</DialogTitle>
          <DialogContent>
            <TextField
              sx={{ mt: 1 }}
              label="Message"
              fullWidth
              multiline
              minRows={6}
              onChange={(e) => setMessage(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => handleClose()} variant="outlined" color="error">
              Cancel
            </Button>
            <Button variant="outlined" onClick={() => onSubmit()}>Submit</Button>
          </DialogActions>
        </Dialog>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="h6" color="initial">
          A PortalMerch &copy; Application <br /> Build 1.8.5
        </Typography>
        <Button variant="contained" color="error" onClick={() => handleClose()}>
          Report Problem
        </Button>
      </Grid>
    </Grid>
  );
};

export default Footer;
