import { createSlice } from "@reduxjs/toolkit";

export const contactSlice = createSlice({
    name: "contact",
    initialState: {
        value: {
            searchTerm: "",
            results: [],
            selectedContact: null,
            tempData: null
        }
    },
    reducers: {
        setSearchTerm: (state, action) => {
            state.value.searchTerm = action.payload
        },
        setResults: (state, action) => {
            state.value.results = action.payload
        },
        setSelectedContact: (state, action) => {
            state.value.selectedContact = action.payload
        },
        setTempData: (state, action) => {
            state.value.tempData = action.payload
        },
        resetContacts: (state) => {
            state.value.searchTerm = "";
            state.value.results = []
            state.value.selectedContact = null;
            state.value.tempData = null
        }
    }
})

export const { setSearchTerm, setResults, setSelectedContact, resetContacts, setTempData } = contactSlice.actions;

export default contactSlice.reducer;