import {
  Grid,
  Typography,
  CardHeader,
  Card,
  Button,
  Chip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getTeamService, getUserService } from "../services/loginService";
import { setUserData } from "../features/authSlice";
import InviteUser from "./inviteUser";
import EditUser from "./editUser";

const Users = () => {
  const dispatch = useDispatch();
  const [dialogOpen, toggleDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [editDialogOpen, toggleEditDialog] = useState(false);
  const [team, setTeam] = useState([]);
  const admin = useSelector((state) => state.auth.value.admin);

  useEffect(() => {
    async function getData() {
      const res = await getUserService();
      const teamPromise = await getTeamService();
      setTeam(teamPromise);
      dispatch(setUserData(res));
    }
    getData();
  }, [dispatch]);

  return (
    <Grid item xs={12} textAlign="center" m={3}>
      <Typography variant="h6" color="initial">
        Users
      </Typography>
      {team.map((t) => (
        <Card variant="outlined" sx={{ margin: 1, padding: 1 }} key={t.id}>
          <CardHeader title={t.name} />
          <Typography variant="h6" color="initial" mb={1}>
            {t.email}
          </Typography>
          {admin && (
            <Chip
              clickable
              label="Edit Permissions"
              color="warning"
              onClick={() => {
                setSelectedUser(t);
                toggleEditDialog(true);
              }}
            />
          )}
        </Card>
      ))}
      <br />
      {admin && (
        <Button
          variant="contained"
          color="success"
          onClick={() => toggleDialog(true)}
        >
          Invite User
        </Button>
      )}

      <InviteUser dialogOpen={dialogOpen} handleClose={toggleDialog} />
      <EditUser
        dialogOpen={editDialogOpen}
        user={selectedUser}
        handleClose={toggleEditDialog}
      />
    </Grid>
  );
};

export default Users;
