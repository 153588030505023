import { api, getAuthHeader } from "../axiosConfig";
import { notifyService } from "./notifyService";

export async function inviteUser(email) {
  return await api
    .post("/admin/addUser", email, {
      headers: getAuthHeader()
    })
    .then((res) => {
        notifyService("User Invited!") 
        return res.data;
    })
    .catch((err) => {
      return err;
    });
}
