import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Typography,
  Switch,
  FormControl,
  FormLabel,
  FormHelperText,
  FormControlLabel,
} from "@mui/material";
import { Box } from "@mui/system";
import { inviteUser } from "../services/inviteUserService";
import { useEffect, useState } from "react";
import { getCIDS } from "../services/cidService";
import { updateUserPermissions } from "../services/permissionsService";

const EditUser = (props) => {
  const [admin, setAdmin] = useState(false);
  const [cids, setCIDS] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const handleClose = () => {
    props.handleClose(!props.dialogOpen);
  };

  useEffect(() => {
    async function init() {
      const res = await getCIDS();
      setCIDS(res);
    }
    init();
    if (props.user) {
      setPermissions(props.user.permissions);
      setAdmin(props.user.admin);
    }
  }, [props.user]);

  return (
    <Dialog
      fullWidth
      open={props.dialogOpen ? true : false}
      onClose={handleClose}
    >
      <DialogTitle>
        Edit {props.user ? props.user.name + "'s" : ""} CID Permissions
      </DialogTitle>
      <DialogContent>
        {cids && props.user ? (
          cids.map((c, i) => {
            return (
              <FormControlLabel
                label={c.name}
                key={i}
                control={
                  <Switch
                    checked={permissions.includes(c.id)}
                    onChange={() => {
                      if (!permissions.includes(c.id)) {
                        setPermissions([...permissions, c.id]);
                      } else {
                        const temp = [...permissions];
                        temp.splice(temp.indexOf(c.id), 1);
                        setPermissions(temp);
                      }
                    }}
                  />
                }
              />
            );
          })
        ) : (
          <></>
        )}
        <FormControlLabel
          label="Admin"
          control={
            <Switch checked={admin} onChange={(e) => setAdmin(!admin)} />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="warning"
          onClick={() => {
            updateUserPermissions({
              permissions: permissions,
              admin: admin,
              email: props.user ? props.user.email : null,
            });
            handleClose()
          }}
        >
          Update User
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditUser;
