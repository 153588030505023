import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            main: '#0026ff'
        },
        secondary: {
            main: '#f50057'
        }
    },
    typography: {
        fontFamily: [
            'Raleway',
            'sans-serif'
        ].join(',')
    }
})