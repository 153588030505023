import {
  Grid,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useEffect, } from "react";
import { getUserService } from "../services/loginService";
import { setUserData } from "../features/authSlice";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import Users from "./users";

const Admin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      const res = await getUserService();
      if (!res.name) {
        navigate("/app/newUser");
      }
      dispatch(setUserData(res));
    }
    getData();
  }, [dispatch, navigate]);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" mb={3}>
        Admin Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Users />
      </Grid>
    </Box>
  );
};

export default Admin;
