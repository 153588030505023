import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import { deleteCID } from "../services/cidService";

const ConfirmDelete = (props) => {
  const handleClose = () => {
    props.handleClose(!props.dialogOpen);
  };

  return (
    <Dialog
      fullWidth
      open={props.dialogOpen ? true : false}
      onClose={handleClose}
    >
      <DialogTitle>Are you sure you want to delete this CID</DialogTitle>
      <DialogActions>
        <Button variant="outlined" color="error" onClick={() => handleClose()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => props.deleteCID()}
        >
          Confirm Delete CID
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDelete;
