import { useDispatch, useSelector } from "react-redux";
import { Chip, Grid, Typography, TextField, Button } from "@mui/material";
import { changeLegend } from "../features/cidSlice";
import { updateCID } from "../services/cidService";
const Legend = () => {
  const legend = useSelector((state) => state.cid.value.legend);
  const touched = useSelector((state) => state.cid.value.touched);
  const data = useSelector((state) => state.cid.value);
  const id = useSelector((state) => state.assessor.value.form.cid);

  const dispatch = useDispatch();
  return (
    <Grid container justifyContent="center" alignItems="center" spacing={3}>
      <Grid item xs={1}>
        <Typography variant="button">Legend</Typography>
      </Grid>
      <Grid item xs={2}>
        <TextField
          label={<Chip sx={{ backgroundColor: "blue", minWidth: 50}}></Chip>}
          value={legend[0] ? legend[0] : ""}
          onChange={(e) =>
            dispatch(changeLegend({ index: 0, data: e.target.value }))
          }
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label={<Chip sx={{ backgroundColor: "red", minWidth: 50 }}></Chip>}
          value={legend[1] ? legend[1] : ""}
          onChange={(e) =>
            dispatch(changeLegend({ index: 1, data: e.target.value }))
          }
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label={<Chip sx={{ backgroundColor: "green", minWidth: 50 }}></Chip>}
          value={legend[2] ? legend[2] : ""}
          onChange={(e) =>
            dispatch(changeLegend({ index: 2, data: e.target.value }))
          }
        />
      </Grid>
      <Grid item xs={2}>
        <TextField
          label={<Chip sx={{ backgroundColor: "orange", minWidth: 50 }}></Chip>}
          value={legend[3] ? legend[3] : ""}
          onChange={(e) =>
            dispatch(changeLegend({ index: 3, data: e.target.value }))
          }
        />
      </Grid>
      <Grid item xs={1}>
        <Button
          variant="contained"
          color="warning"
          disabled={!touched}
          onClick={() => {
            updateCID({ id: id, ...data });
          }}
        >
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default Legend;
