import { createSlice } from "@reduxjs/toolkit";

const cidSlice = createSlice({
  name: "cid",
  initialState: {
    value: {
      name: "",
      notes: "",
      legend: [],
      touched: false,
    },
  },
  reducers: {
    changeName: (state, action) => {
      state.value.name = action.payload;
    },
    changeNotes: (state, action) => {
      state.value.notes = action.payload;
    },
    changeLegend: (state, action) => {
      state.value.touched = true;
      if (action.payload.data !== undefined) {
        state.value.legend[action.payload.index] = action.payload.data;
      }
    },
    loadLegend: (state, action) => {
      state.value.legend = action.payload;
    },
  },
});

export const { changeName, changeLegend, changeNotes, loadLegend } =
  cidSlice.actions;

export default cidSlice.reducer;
