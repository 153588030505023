import { useForm, Controller } from "react-hook-form";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Button,
  Grid,
  MenuItem,
  TextArea,
} from "@mui/material";
import { addContacts } from "../services/contactService";

const CreateContact = (props) => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      name: "",
      phoneNumber: "",
      email: "",
      company: "",
      secondaryContact: "",
      secondaryPhone: "",
      secondaryEmail: "",
      notes: "",
    },
  });

  const onSubmit = async (data) => {
    props.handleClose();
    addContacts(data);
  };

  return (
    <Dialog
      open={props.dialogOpen ? true : false}
      onClose={() => props.handleClose()}
    >
      <DialogTitle>Create Contact</DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={1}
          p={1}
          justifyContent="center"
          textAlign="center"
        >
          <Grid item xs={12}>
            <Controller
              name="name"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <TextField fullWidth {...field} label="Name" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField fullWidth {...field} label="Phone Number" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField fullWidth {...field} label="Email" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="company"
              control={control}
              render={({ field }) => (
                <TextField fullWidth {...field} label="Company" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="secondaryContact"
              control={control}
              render={({ field }) => (
                <TextField fullWidth {...field} label="Secondary Contact" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="secondaryEmail"
              control={control}
              render={({ field }) => (
                <TextField fullWidth {...field} label="Secondary Email" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="secondaryPhone"
              control={control}
              render={({ field }) => (
                <TextField fullWidth type="tel" {...field} label="Secondary Phone #" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="notes"
              control={control}
              render={({ field }) => (
                <TextField
                  multiline
                  minRows={3}
                  fullWidth
                  {...field}
                  label="Notes"
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.handleClose()}
          color="error"
          variant="outlined"
        >
          Cancel
        </Button>

        <Button
          onClick={handleSubmit(onSubmit)}
          color="success"
          variant="outlined"
        >
          Create Contact
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateContact;
