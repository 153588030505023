import { useEffect, useCallback } from "react";
import { loginService } from "../services/loginService";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../features/authSlice";
import { CircularProgress } from "@mui/material";

export const HandleLogin = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const params = useParams();
    const token = params.token;

    const loginCallback = useCallback(async () => {
        if (token?.length) {
            await loginService({ token }).then((res) => {
                if (res.ok) {
                  dispatch(login({ accessToken: res.accessToken, admin: res.admin}))
                  navigate("/app/dashboard", { replace: true });
                } else {
                  console.log("Invalid Login")
                }
              }).catch((e) => console.log(e)); 
        } else {
            console.log("No Token Found")
        }
      }, [dispatch, navigate, token]); // Dependencies array is empty, so this function is memoized for the lifetime of the component
    
      // useEffect to call login when the component mounts
      useEffect(() => {
        loginCallback();
      }, [loginCallback]); 

    return (
      <CircularProgress />
    );
  };