import {
  Grid,
  Typography,
  CardHeader,
  Card,
  CardContent
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getUserService } from "../services/loginService";
import { setUserData } from "../features/authSlice";
import InviteUser from "./inviteUser";
import { useNavigate } from "react-router-dom";
import EditUser from "./editUser";

const Dashboard = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth.value.user);
  const [dialogOpen, toggleDialog] = useState(false);
  const [editDialogOpen, toggleEditDialog] = useState(false);
  const [passwordOpen, togglePassword] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function getData() {
      const res = await getUserService();
      if (!res.name) {
        navigate("/app/newUser");
      }
      dispatch(setUserData(res));
    }
    getData();
  }, []);

  return (
    <Grid container textAlign="center" m={3}>
      <InviteUser dialogOpen={dialogOpen} handleClose={toggleDialog} />
      <Grid item xs={6}>
        <Card variant="outlined">
          <CardHeader title="Profile" subheader="" />
          <CardContent>
            <Grid container>
              <Grid item xs={6}>
                <Typography variant="h6" color="initial">
                  Name
                </Typography>
                <br />
                <Typography variant="h6" color="initial">
                  Email
                </Typography>
                <br />
                <Typography variant="h6" color="initial">
                  Phone Number
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" color="initial">
                  {userData.name}
                </Typography>
                <br />
                <Typography variant="h6" color="initial">
                  {userData.email}
                </Typography>
                <br />
                <Typography variant="h6" color="initial">
                  {userData.phone_number}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
