import { createSlice } from "@reduxjs/toolkit";

export const snackbarSlice = createSlice({
    name: "snackbar",
    initialState: {
        value: {
            open: false,
            message: ""
        }
    },
    reducers: {
        notify: (state, action) => {
            state.value.open = true;
            state.value.message = action.payload;
        },
        close: (state) => {
            state.value.open = false;
        }
    }

})

export const { notify, close } = snackbarSlice.actions;
export default snackbarSlice.reducer;
