import { createSlice } from "@reduxjs/toolkit";

export const assessorSlice = createSlice({
  name: "assessor",
  initialState: {
    value: {
      searchStatus: null,
      cids: [],
      data: [],
      mils: 0,
      form: {
        transferID: -1,
        cid: "",
        parcelID: "",
        ownerName: "NOT FOUND",
        ownerMailing: "NOT FOUND",
        buildingAddress: "NOT FOUND",
        assessorValue: 0,
        cidValue: 0,
        propertyDesc: "",
        contactID: null,
        exempt: false,
        flagColor: null,
        importModalOpen: false,
      },
    },
  },
  reducers: {
    setMarker: (state, action) => {
      state.value.form.flagColor = action.payload
    },
    setMils: (state, action) => {
      state.value.mils = action.payload
    },
    setTransferID: (state, action) => {
      state.value.form.transferID = action.payload;
    },
    changeCID: (state, action) => {
      state.value.form.cid = action.payload.cid;
      state.value.data = action.payload.data;
    },
    pushData: (state, action) => {
      state.value.data.push(action.payload);
    },
    setCID: (state, action) => {
      state.value.form.cid = action.payload;
    },
    setCIDS: (state, action) => {
      state.value.cids = action.payload;
    },
    setData: (state, action) => {
      state.value.data = action.payload;
    },
    setImportModal: (state, action) => {
      state.value.form.importModalOpen = action.payload;
    },
    resetForm: (state) => {
      state.value.form = {
        transferID: -1,
        cid: state.value.form.cid,
        parcelID: "",
        ownerName: "NOT FOUND",
        ownerMailing: "NOT FOUND",
        buildingAddress: "NOT FOUND",
        assessorValue: 0,
        cidValue: 0,
        propertyDesc: "",
        contactID: null,
        exempt: false,
      };
      state.value.searchStatus = null;
    },
    deleteProperties: (state, action) => {
      state.value.data.forEach((p, i) => {
        if (action.payload.includes(p.id)) {
          state.value.data.splice(i, 1);
        }
      });
    },
    setForm: (state, action) => {
      state.value.form = action.payload;
    },
    setParcelID: (state, action) => {
      state.value.form.parcelID = action.payload;
    },
    setOwnerName: (state, action) => {
      state.value.form.ownerName = action.payload;
    },
    setOwnerMailing: (state, action) => {
      state.value.form.ownerMailing = action.payload;
    },
    setBuildingAddr: (state, action) => {
      state.value.form.buildingAddress = action.payload;
    },
    setAssessorCIDValue: (state, action) => {
      state.value.form.assessorValue = parseInt(action.payload);
      if (!state.value.form.exempt) {
        state.value.form.cidValue = parseInt(action.payload) * parseFloat(state.value.mils);
      } else {
        state.value.form.cidValue = 0;
      }
      
    },
    setAssessorValue: (state, action) => {
      state.value.form.assessorValue = parseFloat(action.payload)
    },
    setCIDValue: (state, action) => {
      state.value.form.cidValue = parseFloat(action.payload);
    },
    setContact: (state, action) => {
      state.value.form.contactID = action.payload;
    },
    setPropertyDesc: (state, action) => {
      state.value.form.propertyDesc = action.payload;
    },
    setExempt: (state, action) => {
      if (!action.payload) {
        state.value.form.cidValue = state.value.mils * state.value.form.assessorValue;
        state.value.form.exempt = false;
      } else {
        state.value.form.cidValue = 0 * state.value.form.assessorValue;
        state.value.form.exempt = true;
      }
    },
    setSearchStatus: (state, action) => {
      state.value.searchStatus = action.payload
    }
  },
});

export const {
  setCID,
  setParcelID,
  setForm,
  deleteProperties,
  changeCID,
  pushData,
  setCIDS,
  setData,
  setOwnerName,
  setOwnerMailing,
  setBuildingAddr,
  setExempt,
  setAssessorCIDValue,
  setContact,
  setPropertyDesc,
  setCIDValue,
  setSearchStatus,
  setAssessorValue,
  setTransferID,
  resetForm,
  setMils,
  setMarker,
  setImportModal
} = assessorSlice.actions;

export default assessorSlice.reducer;
