import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Select,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Input,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { addCID, getCIDS } from "../services/cidService";
import { useDispatch } from "react-redux";
import { setCIDS } from "../features/assessorSlice";

const CreateCID = (props) => {
  const dispatch = useDispatch();

  const { control, handleSubmit, register, setValue } = useForm({
    defaultValues: {
      name: "",
      mils: 0.005,
      county: "",
      notes: "",
    },
  });

  const onSubmit = async (data) => {
    data.mils = parseFloat(data.mils);
    addCID(data).then(async () => {
      props.handleClose();
      const res = await getCIDS();
      dispatch(setCIDS(res));
    });
  };

  return (
    <Dialog
      open={props.dialogOpen ? true : false}
      onClose={() => props.handleClose()}
    >
      <DialogTitle>Create CID</DialogTitle>
      <DialogContent>
        <Grid container p={1} textAlign="center" spacing={1}>
          <Grid item xs={12} mx="auto">
            <Controller
              name="county"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel>County</InputLabel>
                  <Select fullWidth {...field} label="County">
                    <MenuItem value="cha">Chatham</MenuItem>
                    <MenuItem value="che">Cherokee</MenuItem>
                    <MenuItem value="cla">Clayton</MenuItem>
                    <MenuItem value="cob">Cobb</MenuItem>
                    <MenuItem value="ful">Fulton</MenuItem>
                    <MenuItem value="gwi">Gwinnett</MenuItem>
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField {...field} label="CID Name" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="mils"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <InputLabel>Mils</InputLabel>
                  <Input {...field} type="number" label="CID Name" fullWidth />
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={12} mx="auto">
            <Controller
              name="notes"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  minRows={7}
                  multiline
                  fullWidth
                  label="Notes"
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={() => props.handleClose()}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSubmit)}
          variant="outlined"
          color="success"
        >
          Create CID
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateCID;
