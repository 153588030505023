import { Grid } from "@mui/material";
import { Drawer, Button, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import logo from "../assets/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../features/authSlice";
import { logoutService } from "../services/loginService";
import { resetContacts } from "../features/contactSlice";
import { resetForm } from "../features/assessorSlice";
import { notifyService } from "../services/notifyService";

const NavDrawer = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.auth.value.admin);

  return (
    <Grid container>
      <Grid item>
        <Drawer
          variant="temporary"
          anchor="left"
          open={open}
          onClose={() => setOpen(!open)}
        >
          <Grid container direction="column" textAlign="center" spacing={3}>
            <Grid item>
              <Button onClick={() => setOpen(!open)} sx={{ color: "black" }}>
                <img src={logo} alt="logo" height={50} />
                <ArrowForwardIcon sx={{ padding: 1 }} />
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                sx={{ color: "black" }}
                onClick={() => navigate("/app/dashboard")}
              >
                Dashboard
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => navigate("/app/quickAssessor")}
              >
                QuickAssessor
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                sx={{ color: "black" }}
                onClick={() => navigate("/app/properties")}
              >
                Properties
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                sx={{ color: "black" }}
                onClick={() => {
                  dispatch(resetContacts());
                  dispatch(resetForm());
                  navigate("/app/contacts");
                }}
              >
                Contacts
              </Button>
            </Grid>
            {admin && (
              <Grid item>
                <Button
                  variant="outlined"
                  sx={{ color: "black" }}
                  onClick={() => {
                    navigate("/app/admin");
                  }}
                >
                  Admin
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button
                variant="outlined"
                sx={{ color: "black" }}
                onClick={() => {
                  navigate("/");
                  logoutService();
                  dispatch(logout());
                }}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </Drawer>
        <Button onClick={() => setOpen(!open)} sx={{ color: "black" }}>
          <ArrowBackIcon sx={{ padding: 1 }} />
          <img src={logo} alt="logo" height={50} />
        </Button>
      </Grid>
    </Grid>
  );
};

export default NavDrawer;
