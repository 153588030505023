import { Modal, Box, Typography, Button } from "@mui/material";
import { setImportModal } from "../features/assessorSlice";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";
import { importService } from "../services/importService";
import { notifyService } from "../services/notifyService";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ImportModal = () => {
  const assessor = useSelector((state) => state.assessor.value.form);
  const dispatch = useDispatch();

  const fileInputRef = useRef(null);

  const [dragging, setDragging] = useState(false);
  const [fileList, setFileList] = useState([]);

  const dragAndDropBoxStyle = {
    backgroundColor: dragging ? "#0026ff" : "#ececec",
    color: "black",
    height: "300px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignContent: "center",
    textAlign: "center",
    justifyContent: "center",
    marginBottom: "10px",
    borderRadius: "10px",
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const files = Array.from(event.dataTransfer.files);
    setFileList(files);
  };

  const handleChange = (event) => {
    const files = Array.from(event.target.files);
    setFileList(files);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = `https://test.portalmerch.com/uploadTemplate.csv`; // Path to your file
    link.download = 'uploadTemplate.csv';
    link.click();
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("files[]", file); // 'files[]' is the key used to send multiple files
    });

    try {
      const response = await importService(assessor.cid, formData);
      if (response.success) { 
        notifyService("Upload successful");
        dispatch(setImportModal(false));
        setFileList([]);
      } else {
        setFileList([]);
        notifyService(`Upload failed ${response.data}`);
      }
    } catch (error) {
      setFileList([]);
      notifyService(`Upload failed ${error.message}`);
    }
  };

  return (
    <Modal
      open={assessor.importModalOpen}
      onClose={() => dispatch(setImportModal(false))}
    >
      <Box sx={modalStyle}>
        <Typography variant="h6" component="h2">
          Import a file
        </Typography>

        <Box
          sx={dragAndDropBoxStyle}
          onClick={handleClick}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {dragging ? "Drop files here!" : "Click or drag files here"}
          {fileList.map((file) => (
            <div key={file.name}>
              {file.name}
            </div>
          ))}
          <input
            style={{ display: "none" }}
            type="file"
            accept=".csv"
            ref={fileInputRef}
            multiple
            onChange={handleChange}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "space-around",
          }}
        >
          <Button variant="contained" color="warning" onClick={handleDownload}>
            Download Format Template
          </Button>
          <Button
            onClick={handleUpload}
            disabled={!fileList?.length}
            variant="contained"
            color="success"
          >
            Upload
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ImportModal;
