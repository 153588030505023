import { api } from "../axiosConfig";
import { store } from "../store";
import { login, logout } from "../features/authSlice";


export async function refreshTokenService() {
    return await api.post("/auth/refreshToken", {}, { withCredentials: true }).then((res) => {
        return res.data;
    }).catch((err) => {
        return err
    })
}
