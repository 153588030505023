import { CircularProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";

const Loading = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  });

  return loading ? (
    <Box textAlign="center" mt={3}>
      <CircularProgress />
    </Box>
  ) : (
    <Typography variant="h5" textAlign="center" sx={{ mt: 1 }}>
      An Error Occured
    </Typography>
  );
};

export default Loading;
