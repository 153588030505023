import { api, getAuthHeader } from "../axiosConfig";
import { changeCID } from "../features/assessorSlice";
import { store } from "../store";
import { notifyService } from "./notifyService";

export async function getCIDS() {
  return await api
    .get("/cids/getCIDS", { headers: { "Authorization" : `Bearer ${store.getState().auth.value.accessToken }`} })
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return null;
    });
}

export async function addCID(data) {
  return await api
    .post("/cids/addCID", {
      name: data.name,
      notes: data.notes,
      county: data.county,
      mils: data.mils
    }, { headers: { "Authorization" : `Bearer ${store.getState().auth.value.accessToken }`} })
    .then((res) => {
      notifyService("CID Added")
      return res.data;
    })
    .catch((err) => console.log(err));
}

export async function getCIDProperties(cid) {
  return await api
    .get("/cids/getCIDProperties", { params: { cid: cid }, headers: { "Authorization" : `Bearer ${store.getState().auth.value.accessToken }`} } )
    .then((res) => {
      return { cid: cid, data: res.data }
    })
    .catch((err) => console.log(err));
}

export async function getCIDContacts(cid) {
  return await api
    .get("/cids/getCIDContacts", { params: { cid: cid }, headers: { "Authorization" : `Bearer ${store.getState().auth.value.accessToken }`} } )
    .then((res) => {
      return res.data
    })
    .catch((err) => { return null });
}


export async function updateCID(data) {
  return await api.put("/cids/updateCID", data, { headers: getAuthHeader() }).then((res) => {
    notifyService("Updated CID")
  }).catch((err) => {
    notifyService("Failed to update CID")
    throw err.message;
  })
}

export async function getCIDInfo(cid) {
  return await api.get("/cids/info", { params: { cid: cid }, headers: getAuthHeader()}).then((res) => {
    return res.data
  }).catch((err) => {
    notifyService("Failed to get CID Info")
    throw err;
  })
}

export async function deleteCID(cid) {
  return await api.delete("/cids/deleteCID", { params: { cid }, headers: getAuthHeader()}).then((res) => {
    notifyService(res.data);
    return true;
  }).catch((err) => {
    notifyService(err.message);
    return false;
  })
}