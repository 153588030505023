import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Select,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  Input,
} from "@mui/material";
import { addCID, getCIDS, updateCID } from "../services/cidService";
import { useDispatch, useSelector } from "react-redux";
import { setCIDS } from "../features/assessorSlice";
import { changeName, changeNotes } from "../features/cidSlice";

const ModifyCID = (props) => {
  const dispatch = useDispatch();

  const cidID = useSelector((state) => state.assessor.value.form.cid);
  const name = useSelector((state) => state.cid.value.name);
  const notes = useSelector((state) => state.cid.value.notes);
  const data = useSelector((state) => state.cid.value);

  const onSubmit = async () => {
    await updateCID({
      id: parseInt(cidID),
      ...data,
    })
      .then(() => props.handleClose())
      .catch(() => {
        props.handleClose();
      });
  };

  return (
    <Dialog
      open={props.dialogOpen ? true : false}
      onClose={() => props.handleClose()}
    >
      <DialogTitle>Modify CID</DialogTitle>
      <DialogContent>
        <Grid container p={1} textAlign="center" spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="CID Name"
              value={name}
              fullWidth
              onChange={(e) => dispatch(changeName(e.target.value))}
            />
          </Grid>

          <Grid item xs={12} mx="auto">
            <TextField
              minRows={7}
              multiline
              fullWidth
              label="Notes"
              value={notes}
              onChange={(e) => dispatch(changeNotes(e.target.value))}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={() => props.handleClose()}
        >
          Cancel
        </Button>
        <Button
          onClick={() => onSubmit()}
          variant="outlined"
          color="success"
        >
          Modify CID
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModifyCID;
