import { api } from "../axiosConfig";
import { store } from "../store";
import { notifyService } from "./notifyService";
export async function searchParcel(data) {
  return await api
    .post("/quickAssessor/lookUp", data, { headers: { "Authorization" : `Bearer ${store.getState().auth.value.accessToken }`}})
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
}

export async function addProperty(data) {
  console.log(data)
  return await api
    .post("/properties/addProperty", data, { headers: { "Authorization" : `Bearer ${store.getState().auth.value.accessToken }`}})
    .then((res) => {
      notifyService(res.data)
    })
    .catch((err) => console.log(err));
}
