import { Controller, useForm } from "react-hook-form";
import { Grid, TextField, Button, Typography } from "@mui/material";
import { newUser } from "../services/newUserService";
import { useNavigate } from "react-router-dom";

const NewUser = () => {
  const navigate = useNavigate();

  const { control, handleSubmit, formState: { errors }, setError } = useForm({
    defaultValues: {
      name: "",
      phoneNumber: "",
    },
  });

  const onSubmit = async (data) => {
    newUser(data).then(() => {
      navigate("/app/dashboard", { replace: true });
    }).catch(() => setError("name"));
  };

  return (
    <Grid container textAlign="center" justifyContent="center" spacing={3}>
      <Grid item xs={12}>
        <Typography variant="button" color="initial">
          Welcome to Leithead <br /> Fill in your details below
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => <TextField {...field} label="Name" />}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="phoneNumber"
          control={control}
          render={({ field }) => <TextField {...field} type="tel" label="Phone Number" />}
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="outlined" onClick={handleSubmit(onSubmit)}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default NewUser;
