import { api, getAuthHeader } from "../axiosConfig";
import { store } from "../store";
import { notifyService } from "./notifyService";

// TODO implement UPDATE && DELETE

export async function deleteProperties(ids) {
  return await api
    .delete("/properties/deleteProperties", {
      data: { ids: ids },
      headers: getAuthHeader(),
    })
    .then(() => {
      return notifyService("Deleted Property!");
    })
    .catch(() => {
      return notifyService("Failed to delete property");
    });
}

export async function searchProperties(data, filter) {
  return await api
    .get("properties/searchProperties", {
      params: { data: data, filter: filter },
      headers: getAuthHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      notifyService("Search Failed");
      throw err.message;
    });
}

export async function getProperty(id) {
  return await api
    .get("/properties/getProperty", {
      params: { id },
      headers: getAuthHeader(),
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      notifyService("Failed to get property");
      throw err;
    });
}

export async function updatePropertyContact(id, contact_id) {
  return await api.put("/properties/updatePropertyContact", { id: id, contact_id: contact_id}, { headers: getAuthHeader()}).then((res) => {
    notifyService(res.data)
  }).catch((err) => {
    notifyService(err.message)
    throw err
  })
}
export async function updateProperty(data) {
  return await api
    .put("/properties/updateProperty", data, {
      headers: {
        Authorization: `Bearer ${store.getState().auth.value.accessToken}`,
      },
    })
    .then((res) => {
      notifyService("Updated Property!");
      return res.data;
    })
    .catch((err) => {
      notifyService("Failed to update property");
      throw err;
    });
}
